/* General Styles */
.navbar-wrapper {
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    top: 0;
    height: auto;
    position: fixed;
    font-family: "Doppio One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: var(--space-s-200);
    background: transparent;
    transition: background 0.3s ease-in-out; /* Smooth transition for background */
}

.navbar-wrapper.scrolled {
    background: #fff; /* Turn the background white when scrolled */

}
.navbar-wrapper.scrolled .navbar a {
    color: var(--color-tertiary);
    transition: var(--bg-transition);

}

.navbar-wrapper.scrolled .navbar a:hover {
    color: var(--color-tertiary-variant);

}

.navbar {
    display: flex;
    gap: var(--space-m-400);
}

.navbar a {
    color: var(--color-secondary);
    text-decoration: none;
    transition: var(--bg-transition);
}

.navbar a:hover {
    color: var(--color-tertiary-variant); /* Change color on hover for visibility */
    transform: scale(1.2);
}




