/*MAIN PAGE*/
#home{
    color: #fafbf6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ;
}
/*-----------------------*/
/*INTRO*/


.background-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.fade-in {
    animation-name: fadeIn;
}

.fade-out {
    animation-name: fadeOut;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--color-primary);
}
.content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-s-100);
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);


}
#name{
    font-family: "gambado-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3vw;
}
#title{
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-size: 1.2vw;
    letter-spacing: 1px;
}

.resume-btn{
    font-family: "Russo One", sans-serif;
    font-style: normal;
    text-decoration: none;
    color: white;
    background-color: var(--color-quaternary);
    font-size: var(--space-s-300);
    padding: var(--space-s-100);
    border-radius: var(--border-radius);
    transition: background-color 0.3s ease-in-out;
}

.resume-btn:hover{
    background-color:var(--color-quaternary-variant);
}

.resume{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-s-200);
    height: 90%;
}

.socials{
    display: flex;
    flex-direction: row;
    gap:var(--space-s-200);
    height: 10%;

}

.socials a{
    color: #fff;
    text-decoration: none;
}

/*-----------------------*/
/*SKILLS*/

.skills-wrapper{
    width: 100%;
    font-family: "lores-12", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 5vw;
    gap: 1vw 3vw;
    display: grid;
    grid-template-columns: repeat(6,6vw);
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 2vw;
    margin-bottom: 5vw;

}
.skills-wrapper>li>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-items: center;
}
/*-----------------------*/
/*RECENT-PROJECTS*/

.home-container{
    display:inline-block ;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    scroll-behavior: smooth;
    overflow-y:hidden ;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1rem 3rem ;
}

.recent-projects {
    font-family: "Russo One", sans-serif;
    font-style: normal;
    display: grid;
    grid-template-columns: repeat(10,auto);
    gap: 1vw;
    margin-top: 1.5vh;
    overflow-x: scroll;
    overflow-Y:hidden ;
    padding: 2rem;
    margin-bottom: 5vw;
}

#about-me-container{
    color: var(--color-primary);
    background-color: var(--color-secondary);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--space-l-900);
}
#about-me{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: var(--space-l-800);
    align-items: center;
    font-family: "Doppio One", sans-serif;
    font-weight: 400;
    font-style: normal;

}

#about-me>div{
    display: flex;
    flex-grow: 0;
    align-items: flex-start;
    flex-direction: column;
    gap: var(--space-m-400);
}
#about-me>div>h2{
    font-family: "gambado-sans", sans-serif;
    font-weight: bold;
    color: var(--color-tertiary);
}

#profile-pic{
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}
@media only screen and (max-width: 1024px) {
    #intro{
        display: flex;
        flex-direction: column;

    }

    #home-thumbnail{
        width: 80vw;
        height: 30vh;
    }
    #name{
        font-size: 6vw;
    }
    #title{
        font-size: 2vw;
    }
    .section-titles{
        font-size: 4vw;
    }
    .line{
        width:80vw;
    }
    #about-me{
       display: flex;
        flex-direction: column;

    }
    .skills-wrapper{
        font-size: 7vw;
        gap: 2vw 5vw;
        grid-template-columns: repeat(6,7vw);
    }

    .thumbnail-text-wrapper{
        font-size: 2.5vw;
    }

    .thumbnail-description{
        font-size: 2vw;
    }



}

@media only screen and (max-width: 768px) {

    .skills-wrapper{
        font-size: 9vw;
        gap: 3vw 6vw;
        grid-template-columns: repeat(6,9vw);
    }
    #intro{
        margin-top: 4rem;
        min-height: 50vh;
    }
    #buttons>a{
        font-size: 3vw; ;
    }
    .recent-projects {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .home-thumbnail{
        width: 70vw;
        height: 30vh;


    }
    #name{
        font-size: 8vw;
    }
    #title{
        font-size: 3vw;
        text-wrap: normal;
    }
    .about-me{
        font-size: 2.5vw;
    }

}