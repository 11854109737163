#fs-wrapper{
    color: #fff;
    background-color: var(--color-primary);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--space-l-900);


}

#fs-header{
    display: flex;
    flex-direction: column;
    gap: var(--space-m-400);
    font-family: "gambado-sans", sans-serif;
    font-weight: bold;
    color: var(--color-tertiary);
}

#fs-title>h2{
    display: inline-flex;
    align-items: center;
    gap: var(--space-s-100);
    white-space: nowrap;
}


#fs-container{
    display: flex;
    flex-direction: column;
    gap: var(--space-l-800);
}

#project-thumbnail-container{
    display: grid;
    grid-template-columns: repeat(3,4fr);
    gap: var(--space-s-200);
    width: 100%;
    height: auto;

}

.project-thumbnail{
    text-decoration: none;
    color: #fff;
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: var(--space-s-200);
    transition: transform 0.3s ease-in-out;

}

.project-thumbnail>img{
    height: 16rem;
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);

}

.project-thumbnail:hover{
    transform: scale(1.05); /* Slightly scale up the image */

}

.fs-icons{
    width: 100%;
    height: 100%;
    font-size: var(--space-m-600);
    display: grid;
    grid-template-columns: repeat(auto-fit,var(--space-m-600));
    gap:var(--space-s-100);
    color: #fff;
}

.thumbnail-text-wrapper{
    display: flex;
    flex-direction: column;
    gap: var(--space-s-100);
}

.thumbnail-text-wrapper>h3{
    font-family:"Doppio One", sans-serif;
    font-style: normal;
    color: var(--color-quaternary);
    transition:var( --bg-transition);

}
.project-thumbnail:hover h3 {
    color: var(--color-quaternary-variant);
}

.thumbnail-text-wrapper>div{
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

#see-more{
    font-family: "gambado-sans", sans-serif;
    font-size: var(--space-s-200);
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: var(--space-s-100);
}



#see-more>span{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--space-s-100);
    white-space: nowrap;
    background-color: var(--color-tertiary);
    padding: var(--space-s-100);
    border-radius: var(--border-radius);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transition: var(--bg-transition);
}
#see-more>span:hover{
    background-color:var(--color-tertiary-variant);
    cursor: pointer;
}

.hidden{
    display: none;
}

@media only screen and (max-width: 1024px) {
    #fs-container{
        gap: var(--space-m-400);
    }
    #project-thumbnail-container {
        display:flex;
        flex-direction: column;
        gap: var(--space-m-500);
    }
    #fs-title>h2{
        font-size: var(--space-s-200);
    }
}

